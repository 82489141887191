<template>
    <el-container>
        <el-main>
            <Upload @success="handleSuccess" :imgUrl="imgUrl"/>
        </el-main>
    </el-container>
</template>

<script>
    import Upload from '../components/upload/index'
    export default {
        name: "logs",
        components: {
            Upload
        },
        data() {
            return {
              imgUrl: 'https://statics.jijisoft.com/556bbdcd43b741c4871769b355144b36'
            };
        },
        methods: {
            handleSuccess(res) {
                this.imgUrl = res.data.url
                console.log(res)
            }
        }
    }
</script>

<style lang="less">

</style>
